import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

import Bandhistory from './Bandhistory';
import Main from './Main/Main';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Main />} />
                <Route path="/bandhistory" element={ <Bandhistory />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
