import styled from "styled-components";
import Socials from "./Socials";

export default function NewAlbum() {
    return (
        <StyledAlbum>
            <Socials />

            <div className="album">
                <span className="subtitle">New Album</span>
                <img src="img/cover.webp" alt="Cover of Future II"/>
                <span className="subtitle">Coming Soon</span>
            </div>
        </StyledAlbum>
    )
}

const StyledAlbum = styled.div`
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: var(--bg-dark-0);
    
    .album {
        min-height: 70svh;
        margin-top: 10vh;
        margin-bottom: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5vh;

        img {
            width: 50vw;
            height: 50vw;
            box-shadow: 0 0 5vh #78008555;
        }

        .subtitle {
            color: var(--fg-light-1);
            font-size: 6vw;
            font-family: var(--font-1);
            font-weight: 400;
            font-style: normal;
        }
    }
`;